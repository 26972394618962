<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
    <div>
        
        <bread-crumbs :items="items"></bread-crumbs>
        
        <tabs-comp :tabs="tabs" />

        <v-row class="mt-10">
        <v-col
          cols="12"
          md="10"
          sm="12">
          <form autocomplete="off">
          <v-row>
            <v-col cols="12" md="2" sm="12">
              <label>تاريخ اليوم</label>
              <b-input-group>
                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
              </b-input-group>
            </v-col>
            <v-col cols="12" md="2" sm="12">
              <label></label>
              <b-input-group style="margin-top:-5px">
                <b-button variant="light" style="background:blue;color:#fff;width:75px;"  @click="getCurrentAttendees()">{{lang.search}}</b-button>
              </b-input-group>
            </v-col>
          </v-row>
          
        </form>
        </v-col>
        <v-col cols="12" md="2" sm="12" class="text-end pt-7">
          
        </v-col>
      </v-row>
        <v-row style="margin-top:10px;" class="p-2">
            <v-col cols="12" :style="`direction: `+lang.dir+`;`">
                <b-table-simple>
                    <thead>
                        <tr>
                            <th class="text-center backBlack">اسم الموظف</th>
                            <th class="text-center backBlack" style="width:130px;">التاريخ</th>
                            <th class="text-center backBlack" style="width:130px;">التاخير بالدقائق</th>
                            <th class="text-center backBlack" style="width:130px;">نوع الغياب</th>
                            <th class="text-center backBlack" style="width:130px;">ساعات التاخير للشهر</th>
                            <th class="text-center backBlack" style="width:130px;">ايام غياب الشهر</th>
                            <th class="text-center backBlack" style="width:130px;">مبلغ الخصم</th>
                            <th class="text-center backBlack" style="width:130px;">خصم الشهر</th>
                            <th class="text-center backGreen" style="width:100px;">حفظ</th>
                            <!-- <th class="text-center backRed" style="width:100px;">تثبيت</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in rsalary" :key="index">
                            <td>{{item.emp_name}}</td>
                            <td>{{ thisdate }}</td>
                            <td><b-form-input class="inborder" v-model="rsalary[index].mlate"/></td>
                            <td>
                                <b-form-select class="selborder" @change="changeType(index)" :options="atType" v-model="rsalary[index].type" />
                            </td>
                            <td>{{ item.monthlate }}</td>
                            <td>{{ item.dayslate }}</td>
                            <td><b-form-input class="inborder" v-model="rsalary[index].damount"/></td>
                            <td>{{ item.mdeduct }}</td>
                            <td @click="save(index)" style="text-align:center;border:2px solid #fff !important;background:green;color:#fff !important;cursor:pointer;">حفظ</td>
                            <!-- <td style="text-align:center;border:2px solid #fff !important;background:red;color:#fff !important;cursor:pointer;">تثبيت</td> -->
                        </tr>
                    </tbody>
                </b-table-simple>
            </v-col>
        </v-row>
    </div>

        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import ExportsPrint from '@/components/exports-print.vue'
export default {
  components: { ExportsPrint,breadCrumbs, TabsComp, HeaderPortrate, Footer },
    name: 'salary-table',
    data() {
        return {
            thisdate: '',
            employees:[],
            cardsrows: [],
            invoicerows: [],
            rewarded: [],
            payadvanced: [],
            deducts: [],
            custodies: [],
            salaryregisterd: [],
            endofservice: 0,
            atType: [
                { text: 'تأخير حضور', value: 1},
                { text: 'انصراف مبكر', value: 21},
                { text: 'غياب', value: 3},
            ],
            months: [
                {text: "January",value:1},
                {text: "February",value:2},
                {text: "March",value:3},
                {text: "April",value:4},
                {text: "May",value:5},
                {text: "June",value:6},
                {text: "July",value:7},
                {text: "August",value:8},
                {text: "September",value:9},
                {text: "October",value:10},
                {text: "November",value:11},
                {text: "December",value:12},
            ],
            month: '',
            year: 2022,
            rsalary: [],
            ppsalary: [],
            sd: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear() - 1,
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            }
        }
    },
    created() {
        this.getCurrentAttendees();
    },
    methods: {
        changeType(index){
            if(this.rsalary[index].type == 3){
                this.rsalary[index].mlate = 3600;
            }
        },
        save(index){
            const post = new FormData();
            post.append("type" , "saveAllEmpAttend");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.rsalary[index].id);
            post.append('data[mlate]',this.rsalary[index].mlate);
            post.append('data[damount]',this.rsalary[index].damount);
            post.append('data[type]',this.rsalary[index].type);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                this.getCurrentAttendees();
            })
        },
        getCurrentAttendees(){
            this.thisdate = this.sdate;
            const post = new FormData();
            post.append("type" , "getAllEmpAttend");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[thisdate]',this.thisdate);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                this.rsalary = res.results.data;
                
            })
        },
        preparedata(){
            let exdata = {
                list: "salaryTableList",
                title: this.lang.salary_table,
                data: [],
            }
            for(let i=0;i<this.rsalary.length;i++){
                exdata.data.push(this.rsalary[i]);
            }
            // console.log(exdata);
            this.$refs.exportbar.exportdata = exdata;
            this.$refs.exportbar.sdate = 'عن شهر '+this.month+' لسنة '+this.year;
            this.$refs.exportbar.edate = '';
            
            this.$refs.exportbar.exporttitle = "";
            this.$refs.exportbar.palte_number = '';
            this.$refs.exportbar.contact_number = '';
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?invid='+id;
        },
        exportE(type){
            const post = new FormData();
            post.append("export","salary");
            post.append("type",type);
            post.append('month',this.month);
            post.append('year',this.year);
            axios.post(
                this.$store.state.SAMCOTEC.e_path , post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                window.open('../api/'+res.url,'_blank');
            })
        },
        getMonth(){
            let tm = new Date();
            this.month = parseFloat(tm.getMonth()) + parseFloat(1);
            this.year = tm.getFullYear()
        },
        getEmployees() {
            const post = new FormData();
            post.append("type" , "getAllEmpTable");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[month]',this.month);
            post.append('data[year]',this.year);
            
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                this.rsalary = [];
                const res = response.data.results.data;
                for(let i=0;i<res.length;i++){
                    
                    this.rsalary.push(res[i]);
                    
                }
                // // console.log("asdfasdf",res);
            })
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        sdate: function() {
          return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
          return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
          const start_year = this.$store.state.licenseType.startYear;
          let t = [{text: this.lang.year, value: -1}];
          for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
              t.push({text: start_year + i, value: start_year+i})
            }
            
          }
          return t
        },
        daysList: function(){
          return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
          ]
        },
        monthsList: function(){
          return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
          ]
        },
        items: function() {
            return {
                    text: 'مسير الرواتب',
                    disabled: true,
                    to: '/emp/view',
                }
        },
        tabs: function(){
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        // thisdate: function(){
        //     let t = '';

        //     return t;
        // }
    }
}
</script>
